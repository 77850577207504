/* @media screen and (min-width: 601px) { */
    div.Container{
        display: flex;
        /* border: 1px solid #e6e6e6; */
        border-radius: 20px;
        margin: 20px;
        padding: 10px;
        padding-top: 10px;

        div.RightElements{
            /* border: 1px solid blue; */
            width: 30%;

            div.Checked{
                display: flex;
                justify-content: flex-start;
            }
        }
        div.MidElements{
            /* border: 1px solid green; */
            width: 55%;
            display: flex;
            flex-direction: column;
            position: relative;

            div.ItemDetails{
                /* height: 100%; */
                padding: 0;
                margin: 0;
                margin-right: 10px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                text-align: right;
                overflow: hidden;
                overflow-x: auto;
                line-height: 120%;
                
                div.ItemName{
                    /* position: absolute; */
                    /* top: 0; */
                    font-size: 15px;
                    font-weight: 500;
                    /* overflow: hidden; */
                    /* text-overflow: ellipsis; */
                    /* width: 200px; */
                    /* text-align: right; */
                }
                div.ItemPrice{
                    font-size: 18px;
                    font-family: 'Heebo', sans-serif;
                    font-weight: 500;
                    cursor: default;
                }
            }
            div.ItemButtons{
                display: flex;
                position: absolute;
                bottom: 0;
                /* gap: 10px; */

                div.ItemUnitsBtn{
                    /* color: #DC143C; */
                    border-radius: 10px;
                    width: 60px;
                    background-color: #FFFFFF;
                    /* border: 1px solid #DC143C; */
                    outline: none;
                }

                div.ItemAddBtn{
                    /* color: #DC143C; */
                    border-radius: 10px;
                    width: 85px;
                    background-color: #FFFFFF;
                    /* border: 1px solid #DC143C; */
                    outline: none;
                    cursor: pointer;
                }
            }

            div.ItemButtons > * + * {
                margin-right: 10px;
            }
        }
        div.LeftElements{
            width: 15%;

            div.MoreInfoTooltip{
                font-size: 14px;
                text-decoration: underline;
                cursor: help;

                .Tooltip{
                    max-width: 400px !important;
                    text-align: right;
                    white-space: pre-wrap !important;
                }
            }
            div.MoreInfoTooltip > .label:hover{
                opacity: .6;
            }
        }
    }
    div.Container:hover{
        background-color: #F9F9F9;
    }
/* } */

@media (max-width: 550px){
    div.Container{
        div.MidElements{
            div.ItemName{
                font-size: 12px;
            }
        }
        
        div.LeftElements{
            div.MoreInfoTooltip{
                font-size: 10px;
            }
        }
    }
}