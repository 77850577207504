div.Container{
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 70vh;
    /* min-height: 100%; */

    div.Title{
        margin: 15px;
        color: #FFFF;
        font-size: 25px;
    }

    div.UserChoosing{
        /* border: 1px solid red; */
        height: 100%;
        width: 100%;
        div.OptionBtns{
            display: flex;
            justify-content: center;
            /* margin-right: 15px; */
            button{
                /* margin-left: 15px; */
                width: 100%;
                min-height: 30px;
                font-size: 15px;
                outline: none;
                color: #FFFF;
                border: none;
                border-radius: 3px;
                cursor: pointer;
                font-family: 'Heebo', sans-serif;
            }
            button:hover{
                opacity: .9;
            }
        }
        
        div.OptionBtns > * + *{
            margin-right: 3%;
        }

        form{
            /* border: 4px solid green; */
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            height: 90%;
            justify-content: space-between;
            width: 100%;

            input, select, textarea{
                display: inline-block;
                width: 100%;
                height: 30px;
                border:none;
                outline: none;
                border-radius: 3px;
                resize: none;
                overflow: auto;
                margin-bottom: 6px;
                font-family: 'Heebo', sans-serif;
                padding: 2px 5px 2px 5px;
            }

            textarea{
                height: 80px;
                margin-bottom: 6px;
            }

            input::placeholder, textarea::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: #adabab;
                opacity: 1; /* Firefox */
              }
              
            input:-ms-input-placeholder, textarea::placeholder { /* Internet Explorer 10-11 */
                color: #adabab;
              }
              
            input::-ms-input-placeholder, textarea::placeholder { /* Microsoft Edge */
                color: #adabab;
              }
            
            .Commands{
                display: flex;
                flex-direction: column;
            }
            .Btns{
                display: flex;
                flex-direction: column;

                button{
                    margin-top: 15px;
                    height: 50px;
                    border-radius: 30px;
                    outline: none;
                    border:none;
                    color: #FFFF;
                    font-size: 27px;
                    font-family: 'Heebo', sans-serif;
                    cursor: pointer;
                }
    
                button:nth-child(2){
                    background-color: unset;
                    border-radius: 30px;
                    outline: none;
                    border: 2px solid rgb(255, 255, 255, 0.3);
                    color: #FFFF;
                }

                button:hover{
                    opacity: .9;
                }
            }
        }
    }
}


  
@media (max-width: 850px){
    div.Container {
        div.Title{
            font-size: 15px;
        }

        div.UserChoosing{
            form{
                display: flex;
                flex-direction: column;
                margin-top: 10px;
                align-items: center;

                input, select, textarea{
                    width: 260px;
                }

                .Btns{
                    /* gap: 10px; */
        
                    button{
                        margin-top: 10px;
                        width: 250px;
                        height: 30px;
                        font-size: 19px;
                    }
                    
                    button:nth-child(2){
                        width: 250px;
                        height: 30px;
                    }
                }
            }
        }
    }
}