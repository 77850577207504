  div.Container{
    justify-self: right;
    flex-flow: column nowrap;
    flex: 1;
    margin: 0 0 5px 0;
    border-radius: var(--brdRadius);
    background-color: var(--mainWhite);
    position: relative;
  
    &:last-of-type {
      margin-bottom: 0px;
    }
  
    div.TopDiv {
      /* text-align: center; */
      display: flex;
      flex: 1;
      min-height: 140px;
      flex-flow: row wrap;
      justify-content: space-between;
      color: black;
      padding: 5px;
  
      div.DivLeft {
        flex: 0 0 180px;
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;
        align-content: center;
        padding: 5px;
      }
  
      div.DivMid {
        display: flex;
        justify-content: flex-start;
        flex-flow: column nowrap;
        flex: 3 3;
  
        div.ItemName {
          display: flex;
          word-wrap: break-word;
          padding-top: 5px;
          font-size: 22px;
          text-align: right;

          div {
            word-break: break-word;
          }
        }

        div.DivLeftUp {
          /* border: 1px solid red; */
          color: var(--mainColor);
          display: flex;
          justify-content: flex-end;
          flex-grow: 1;
  
          .DescOuterDiv {
            position: relative;
            height: 30px;
            /* width: 100px; */
            text-align: left;
            cursor: help;
            font-size: 16px;
  
            .label:hover{
              opacity: .6;
            }
            
            .label{
              word-break: normal;
            }

            .DescOuterPopup {
              position: fixed;
              max-width: 500px;
              z-index: 1;
  
              .DescArrow {
                height: 15px;
                width: 15px;
                transform: rotate(45deg);
                border: 1px solid var(--black);
                position: absolute;
                left: -7px;
                top: 24px;
                z-index: 1;
              }
  
              .DescArrow2 {
                height: 13px;
                width: 13px;
                transform: rotate(45deg);
                position: absolute;
                left: -6px;
                top: 25px;
                z-index: 3;
                background-color: var(--mainWhite);
              }
  
              .DescOuterPopupIn {
                border: 1px solid var(--black);
                display: inline-block;
                padding: 10px;
                padding-left: 15px;
                padding-right: 15px;
                background-color: var(--mainWhite);
                text-align: right;
                position: sticky;
                z-index: 2;
                color: var(--black);
                border-radius: var(--brdRadius);
  
                .DescPopupIcon {
                  width: 100%;
                  height: 30px;
                }
  
                .DescPopupText {
                  white-space: pre-wrap;
                }
              }
            }
          }
        }
        
        div.DivLeftUp > * + * {
          margin-right: 20px;
        }
        
        div.DivLeftLeft {
          display: flex;
          align-items: flex-end;
          flex-flow: wrap;

          div.DivLeftLeft1 {
            flex: 1 1;
            min-width: 100px;

            div.ItemPrice {
              flex: 2 2;
              text-align: start;
              /* justify-content: center; */
              padding-top: 26px;
              font-size: 26px;
              font-weight: 600;
            }
            
            div.ItemUnits {
              /* align-self: center; */
              /* display: flex;
              gap: 10px; */
              padding-left: 5px;
      
              div.Unit{
                width: 80px;
                height: 22px;
                font-size: 20px;
                border-radius: var(--brdRadius);
                border:1px solid var(--mainColor);
                margin-bottom: 5px;

                font-size: 17px;
                line-height: 23px;
              }
              div.Unit:hover{
                cursor: default;
              }
            }
          }

          div.DivLeftLeft2 {
            flex: 1 1;
            max-width: 180px;
            min-width: 100px;

            div.DivLeftBottom {
              display: flex;
              flex-flow: row nowrap;
              width: 100%;
              align-content: flex-end;
              justify-content: center;
              border-radius: 50px;
              border-width: 0px;
              height: 45px;
              position: relative;
      
              div.BtnLeftButton {
                align-self: center;
                justify-self: center;
                white-space: nowrap;
                padding: 5px 0 5px 0;
                font-size: 24px;
                color: var(--mainWhite);
                border-radius: 50px;
                border-width: 0px;
                margin-left: 15px;
              }
              div.BtnLeftButton:hover{
                cursor: pointer;
              }
              
              div.PlusLeftButton {
                margin-top: 10px;
                margin-left: 5px;
                width: 25px;
                height: 25px;
                color: white;
                line-height: 25px;
                font-size: 43px;
                font-weight: 300;
                color: var(--mainWhite);
              }
              
              div.PlusLeftButton:hover{
                cursor: pointer;
              }
      
              div.PlusButton{
                position: absolute;
                margin-top: 10px;
                margin-right: 20px;
                right: 10px;
                color: white;
                line-height: 25px;
                font-size: 33px;
                font-weight: 300;
                color: var(--mainWhite);
              }
      
              div.ItemAmount{
                /* position: absolute; */
                margin-top: 10px;
                align: center;
                color: white;
                line-height: 25px;
                font-size: 20px;
                font-weight: 400;
                color: var(--mainWhite);
              }
      
              div.MinusButton{
                position: absolute;
                margin-top: 10px;
                margin-left: 20px;
                left: 10px;
                color: white;
                line-height: 25px;
                font-size: 53px;
                font-weight: 300;
                color: var(--mainWhite);
              }
      
              div.MinusButton:hover{
                cursor: pointer;
              }
              div.PlusButton:hover{
                cursor: pointer;
              }
            }
          }
        }
      }
  
      div.DivRight {
        cursor: pointer;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-evenly;
        align-content: center;
        flex: 2 2;
        max-width: 240px;
        margin: 5px;
  
        img.ItemImg {
          max-width: 135px;
          height: 90px;
          margin: 0px;
        }
  
        div.ItemFlags {
          display: flex;
          text-align: center;
          justify-content: center;
        }
  
        div.iFlags {
          color: var(--mainWhite);
          width: 80%;
          max-width: 200px;
          border-radius: var(--brdRadius);
          height: 24px;
          font-size: 15px;
          font-weight: 500;
  
          &.iNew {
            border:1px solid  #FF8000;
            background-color: #FF8000;
          }
  
          &.iSale {
            border:1px solid  #E10000;
            background-color: #E10000;
          }
  
          &.iSeason {
            border:1px solid  #FF8000;
            background-color: #FF8000;
          }
        }
      }
    }
  
    div.DivNotInStock {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      color: var(--mainWhite);
      border-radius: var(--brdRadius);
      background-color: rgba(70, 70, 70, 0.8); 
      line-height: 140px;
      font-size: 60px;
      font-weight: 600;
    }
  }


/* @media screen and (max-width: 600px) {
    div.TopDiv {
      display: flex;
      flex-flow: row wrap;
    }
    div.ItemIcon {
      width: 50px;
      max-width: 50px;
      max-height: 50px;
      border:1px solid  var(--mainWhite);
      border-radius: 50%;
    }
    img.ItemImg {
      width: 50px;
      height: 50px;
      margin: 0px;
    }
  } */

  @media (max-width: 600px) {
    div.Container {
      div.TopDiv {
        min-height: 100px;
        div.DivRight {
          img.ItemImg{
            max-width: 100px;
            height: 70px;
          }
        }
        div.DivMid {
          flex: 4 4;

          div.ItemName {
            font-size: 16px;
          }

          div.DivLeftUp {
            div.DescOuterDiv {
              font-size: 14px;
            }
          }

          div.DivLeftLeft {

            div.DivLeftLeft1 {
              div.ItemPrice {
                font-size: 20px;
                padding-top: 10px;
              }
            }

            div.DivLeftLeft2 {
              div.DivLeftBottom {
                height: 22px;
                margin-bottom: 5px;

                div.PlusButton {
                  margin-top: 0;
                  margin-right: 5px;
                  font-size: 23px;
                }

                div.ItemAmount {
                  margin-top: 0;
                  font-size: 15px;
                }

                div.MinusButton {
                  margin-top: 0;
                  margin-left: 5px;
                  font-size: 23px;
                }

                div.BtnLeftButton {
                  font-size: 15px;
                }
                
                div.PlusLeftButton {
                  font-size: 23px;
                  line-height: 3px;
                }
              }
            }
          }
        }
      }
    }
  }